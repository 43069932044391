
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component({
  name: 'Tooltip',
})
export default class Tooltip extends Vue {
  @Prop({ required: true })
  private readonly description!: string|undefined;

  @Prop({ default: false })
  private readonly openOnHover!: boolean;

  @Prop({ default: true })
  private readonly openOnClick!: boolean;

  @Prop({ default: 'x-large' })
  private readonly size!: 'x-small'|'small'|'medium'|'large'|'x-large'|number;

  @Prop({ default: 'mdi-information' })
  private readonly icon!: string;

  @Prop({ default: 'var(--v-primary_list_link_color-base)' })
  private readonly color!: string;
}
